import MenuItem from "../../components/main-menu/menuItem";
import PipelineList from "../../components/main-menu/pipelineList";
import useWhiteLabel from "../../hooks/useWhiteLabel";
import ToolsMenu from "../main-menu/toolsMenu";
import { useTranslation } from "react-i18next";
import UserAvatar from "../main-menu/userAvatar";

const MainMenu = () => {

    const { t } = useTranslation();
    const { logo192 } = useWhiteLabel();

    return (
        <div className="main-menu">
            <div className="logo">
                <img src={logo192} alt="" />
            </div>
            <div className="menu-itens">
                <MenuItem to="/chat" icon="ri-chat-3" title={t("Chat")} />
                <MenuItem to="/metrics" icon="ri-line-chart" title={t("Métricas")} />
                <MenuItem to="/contacts" icon="ri-contacts-book" title={t("Contatos")} />
                <MenuItem to="/flows" icon="ri-flashlight" title={t("Fluxos")} />
                <MenuItem to="/settings" icon="ri-settings" title={t("Configurações")} />
                {/* <MenuItem to="/user/4" icon="ri-inbox" title={t("Chat")}></MenuItem> */}
                <ToolsMenu />
            </div>

            <div className="bottom-itens">
                <PipelineList disablePipelineList={true}/>
                <div className="user-avatar">
                    <UserAvatar />
                </div>
            </div>

        </div>
    );
};

export default MainMenu;
