import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import RemixIcon from "../icons/RemixIcon";
// import ChannelPhoneIcon from "../icons/channelPhoneIcon";
import IconBlock from "../icons/IconBlock";
import MenuItemIcon from "../mui-mod/MenuItemIcon";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ToolsMenu = () => {
    const { t } = useTranslation();
    const { user } = useAuth();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [active, setActive] = useState("");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setActive("active");
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActive("");
    };

    const navigateTo = (path) => {
        navigate(path);
        handleClose();
    };

    return (
        <>
            <IconButton onClick={handleClick} className={`menu-item ${active}`}>
                <RemixIcon className="ri-more-fill" />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: {
                        padding: "5px 10px",
                    },
                }}
            >
                <Grid container columns={1} width={{ xs: "120px", md: "120px" }}>
                    {/* <Grid item xs={1} md={1}>
                        <MenuItemIcon
                            onClick={() => navigateTo("/whatsapp-templates")}
                        >
                            <IconBlock>
                                <ChannelPhoneIcon />
                            </IconBlock>
                            <Typography variant="smallTextRegular">
                                Templates <br /> WhatsApp
                            </Typography>
                        </MenuItemIcon>
                    </Grid> */}
                    {/* <Grid item xs={2} md={1}>
                        <MenuItemIcon>
                            <IconBlock color="blue">
                                <RemixIcon className="ri-search-line" />
                            </IconBlock>
                            <Typography variant="smallTextRegular">
                                {t("Buscador")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid> */}
                    <Grid item xs={1} md={1}>
                        <MenuItemIcon onClick={() => navigateTo("/email-verification")}>
                            <IconBlock color="green">
                                <RemixIcon className="ri-mail-check-fill" />
                            </IconBlock>
                            <Typography variant="smallTextRegular">{t("Verificar email")}</Typography>
                        </MenuItemIcon>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <MenuItemIcon onClick={() => navigateTo("/referral")}>
                            <IconBlock color="green">
                                <RemixIcon className="ri-gift-fill" />
                            </IconBlock>
                            <Typography
                                variant="smallTextRegular"
                                sx={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                }}
                            >
                                {t("Programa de indicações")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid>
                    {user.is_admin && (
                        <Grid item xs={1} md={1}>
                            <MenuItemIcon onClick={() => navigateTo("/contacts/import")}>
                                <IconBlock color="green">
                                    <RemixIcon className="ri-upload-line" />
                                </IconBlock>
                                <Typography
                                    variant="smallTextRegular"
                                    sx={{
                                        textAlign: "center",
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {t("Importar contatos")}
                                </Typography>
                            </MenuItemIcon>
                        </Grid>
                    )}
                    <Grid item xs={1} md={1}>
                        <MenuItemIcon onClick={() => navigateTo("/scheduled-events")}>
                            <IconBlock color="green">
                                <RemixIcon className="ri-calendar-line" />
                            </IconBlock>
                            <Typography
                                variant="smallTextRegular"
                                sx={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                }}
                            >
                                {t("Eventos agendados")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        <MenuItemIcon onClick={() => navigateTo("/contacts/batch-actions")}>
                            <IconBlock color="green">
                                <RemixIcon className="ri-list-check-3" />
                            </IconBlock>
                            <Typography
                                variant="smallTextRegular"
                                sx={{
                                    textAlign: "center",
                                    whiteSpace: "normal",
                                }}
                            >
                                {t("Ações em lote")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid>
                    {/* <Grid item xs={2} md={1}>
                        <MenuItemIcon>
                            <IconBlock color="orange">
                                <RemixIcon className="ri-mail-add-fill" />
                            </IconBlock>
                            <Typography variant="smallTextRegular">
                                {t("Descobrir email")}
                            </Typography>
                        </MenuItemIcon>
                    </Grid> */}
                </Grid>
            </Menu>
        </>
    );
};

export default ToolsMenu;
