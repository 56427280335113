import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const MenuItemIcon = styled(MenuItem)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "120px",
    height: "120px",
    paddingTop: "30px",
    borderRadius: "8px",
}));

export default MenuItemIcon;
