import './styles/scss/app.scss';
import MuiThemeMod from './styles/theme/muiThemeMod';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import PageRoutes from "./appRoutes";
import { AuthProvider } from "./contexts/auth";
import { ApiProvider } from "./contexts/api";
import { PipelineProvider } from "./contexts/pipeline";
import { SnackbarProvider } from "./components/snackbar/snackbar";
import { HelmetProvider } from 'react-helmet-async';
import WhiteLabel from './components/white-label/whiteLabel';
import { WhiteLabelProvider } from './contexts/whitelabel';
import { LaravelEchoProvider } from './contexts/laravelEcho';
import { DesktopNotificationProvider } from './contexts/desktopNotification';


function App() {

    const commonThemeSettings = {};

    const themeMerged = { ...commonThemeSettings, ...MuiThemeMod };

    const theme = createTheme(
        themeMerged
    );

    return (
        <ApiProvider>
            <HelmetProvider>
                <WhiteLabelProvider>
                    <WhiteLabel />
                    <PipelineProvider>
                        <AuthProvider>
                            <ThemeProvider theme={theme}>
                                <SnackbarProvider>
                                    <BrowserRouter>
                                        <DesktopNotificationProvider>
                                            <LaravelEchoProvider>
                                                <PageRoutes />
                                            </LaravelEchoProvider>
                                        </DesktopNotificationProvider>
                                    </BrowserRouter>
                                </SnackbarProvider>
                            </ThemeProvider>
                        </AuthProvider>
                    </PipelineProvider>
                </WhiteLabelProvider>
            </HelmetProvider>
        </ApiProvider>
    );

}

export default App;
