const MuiThemeMod = {
    palette: {
        primary: {
            dark: "#B279D9",
            main: "#963CC8",
            light: "#581F77",
        },
        success: {
            dark: "#0C7A4C",
            main: "#1AA569",
            light: "#52CF90",
        },
        warning: {
            dark: "#8F4E06",
            main: "#E27F0D",
            light: "#F0AA71",
        },
        error: {
            dark: "#A63839",
            main: "#FD5B5B",
            light: "#FF9F99",
        },
        chromaticColors: [
            {
                id: 1,
                veryLightColor: "#FDEEEA",
                lightColor: "#E9AA9B",
                defaultColor: "#D7765F",
                darkColor: "#9E412D",
                veryDarkColor: "#672719",
            },
            {
                id: 2,
                veryLightColor: "#FEF7E3",
                lightColor: "#E0BD5C",
                defaultColor: "#BE9626",
                darkColor: "#816312",
                veryDarkColor: "#523F0A",
            },
            {
                id: 3,
                veryLightColor: "#EAF5DC",
                lightColor: "#9BC83C",
                defaultColor: "#799C2E",
                darkColor: "#4D6619",
                veryDarkColor: "#31410E",
            },
            {
                id: 4,
                veryLightColor: "#E4F3F7",
                lightColor: "#7AC4D6",
                defaultColor: "#349DB4",
                darkColor: "#206777",
                veryDarkColor: "#13414B",
            },
            {
                id: 5,
                veryLightColor: "#EDF1FA",
                lightColor: "#A4B8EB",
                defaultColor: "#738EDD",
                darkColor: "#3C57BA",
                veryDarkColor: "#23367A",
            },
        ],
        text: {
            light: "#707274",
        },
        outline: "#E0E2E4",
    },

    typography: {
        fontFamily: ["Source Sans Pro", "Roboto"].join(","),
        h6: {
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: 20,
            lineHeight: "120%",
        },
        teenyTextRegular: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 12,
            lineHeight: "115%",
        },
        teenyTextBold: {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "115%",
        },
        smallTextRegular: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "115%",
        },
        smallTextBold: {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "115%",
        },
        defaultTextRegular: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "115%",
        },
        defaultTextBold: {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "115%",
        },
        bigTextRegular: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 20,
            lineHeight: "120%",
        },
        bigTextBold: {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 20,
            lineHeight: "120%",
        },
        smallMessage: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "140%",
        },
        defaultMessage: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "140%",
        },
        buttonText: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "115%",
        },
        bigNumber: {
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: 36,
            lineHeight: "110%",
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "var(--border-radius)",
                    background: "var(--white)",
                    textTransform: "none",
                    fontWeight: "600",
                    lineHeight: "115%",
                    "&.Mui-disabled": {
                        color: "var(--icon-color-light)",
                        background: "none",
                    },
                },
                sizeMedium: {
                    fontSize: "14px",
                    padding: "11px 16px",
                },
                contained: {
                    color: "var(--white)",
                    "&.Mui-disabled": {
                        color: "var(--icon-color-light)",
                        backgroundColor: "var(--outline-color)",
                    },
                },
                outlined: {
                    "&.Mui-disabled": {
                        color: "var(--icon-color-light)",
                        background: "none",
                    },
                },
                containedPrimary: {
                    backgroundColor: "var(--theme-color-dark)",
                    "&:hover": {
                        backgroundColor: "var(--theme-color-very-dark)",
                    },
                },
                outlinedPrimary: {
                    color: "var(--text-color-default)",
                    borderColor: "var(--theme-color-dark)",
                },
                outlinedSecondary: {
                    color: "var(--text-color-default)",
                    borderColor: "var(--outline-color)",
                    "&:hover": {
                        borderColor: "var(--outline-color)",
                        backgroundColor: "var(--background-color-light)",
                    },
                },
                outlinedDanger: {
                    color: "var(--error-color-default)",
                    border: "var(--borders-danger)",
                    backgroundColor: "inherit",
                },
                textPrimary: {
                    color: "var(--theme-color-dark)",
                    "&:hover": {
                        backgroundColor: "var(--theme-color-very-light)",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: "var(--border-radius)",
                    fontSize: "14px",
                    lineHeight: "115%",
                    color: "var(--text-color-default)",
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--theme-color-dark) !important",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "var(--outline-color)",
                    },
                    "&.Mui-disabled": {
                        background: "none",
                        borderRadius: "var(--border-radius)",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                    },
                },
                input: {
                    borderRadius: "var(--border-radius)",
                    "&::placeholder": {
                        color: "var(--text-color-placeholder)",
                    },
                    "&:hover": {
                        backgroundColor: "var(--background-color-light)",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "var(--background-color-light)",
                    },
                },
                notchedOutline: {
                    borderColor: "var(--outline-color)",
                },
                inputSizeSmall: {
                    padding: "11px 16px",
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "var(--text-color-light)",
                    "&.Mui-focused": {
                        color: "var(--theme-color-dark)",
                    },
                    "&.Mui-disabled": {
                        color: "var(--icon-color-light)",
                    },
                },
                filled: {
                    fontSize: "14px",
                    transform: "none",
                    "&.Mui-focused": {
                        color: "var(--text-color-light)",
                    },
                    "&.Mui-disabled": {
                        color: "var(--text-color-light)",
                    },
                    "&.Mui-error": {
                        color: "var(--text-color-light)",
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "var(--text-color-light)",
                    "&.Mui-focused": {
                        color: "var(--text-color-light)",
                    },
                    fontSize: "14px",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: "var(--text-color-default)",
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    margin: "8px 2px 8px 0px !important",
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    borderRadius: "var(--border-radius)",
                    backgroundColor: "var(--neutral-color)",
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginTop: "1px",
                    marginLeft: "1px",
                    "&.Mui-error": {
                        color: "var(--error-color-default)",
                        backgroundColor: "transparent",
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    marginTop: "26px",
                    border: "1px solid var(--outline-color)",
                    borderRadius: "8px",
                    background: "var(--white)",
                    "&.Mui-error": {
                        borderColor: "var(--error-color-default)",
                        boxShadow: "var(--error-color-default) 0 0 0 1px",
                        backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00004 14.6667C4.31804 14.6667 1.33337 11.682 1.33337 8.00001C1.33337 4.31801 4.31804 1.33334 8.00004 1.33334C11.682 1.33334 14.6667 4.31801 14.6667 8.00001C14.6667 11.682 11.682 14.6667 8.00004 14.6667ZM7.33337 10V11.3333H8.66671V10H7.33337ZM7.33337 4.66668V8.66668H8.66671V4.66668H7.33337Z" fill="rgb(253, 91, 91)"/></svg>')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right 17px bottom 50%",
                    },
                    "&.Mui-focused": {
                        borderColor: "var(--theme-color-dark)",
                        boxShadow: "var(--theme-color-dark) 0 0 0 1px",
                        background: "none",
                    },
                    "&:hover": {
                        borderColor: "var(--theme-color-dark)",
                        boxShadow: "var(--theme-color-dark) 0 0 0 1px",
                        background: "none",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "var(--background-color-light)",
                        border: "1px solid var(--background-color-light)",
                        boxShadow: "none",
                    },
                    "&::before": {
                        content: "none",
                    },
                    "&::after": {
                        content: "none",
                    },
                    "& ::placeholder": {
                        fontSize: 14,
                    },
                    "& .MuiInputAdornment-positionStart": {
                        marginTop: "0 !important",
                        paddingRight: 5,
                    },
                },
                input: {
                    paddingTop: "7px",
                    paddingBottom: "8px",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "&.search": {
                        "& .MuiFilledInput-root": {
                            marginTop: "0 !important",
                        },
                    },
                    "&.no-margin-top": {
                        "& .MuiFilledInput-root": {
                            marginTop: "0 !important",
                        },
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    margin: 0,
                    "& .MuiFilledInput-root.MuiInputBase-sizeSmall": {
                        paddingTop: 5,
                        paddingBottom: 5,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "var(--text-color-light)",
                    "&.MuiRadio-colorPrimary": {
                        "&.Mui-checked": {
                            color: "var(--theme-color-dark)",
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "var(--text-color-light)",
                    "&.MuiCheckbox-colorPrimary": {
                        "&.Mui-checked": {
                            color: "var(--theme-color-dark)",
                        },
                        "&.MuiCheckbox-indeterminate": {
                            color: "var(--theme-color-dark)",
                        },
                        "&.Mui-disabled": {
                            opacity: "0.5",
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.MuiInputBase-multiline": {
                        paddingTop: "8px",
                    },
                },
                adornedEnd: {
                    "& a": {
                        position: "absolute",
                        right: 13,
                        opacity: 1,
                        color: "var(--text-color-light)",
                        pointerEvents: "all",
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    paddingTop: 11,
                    width: 64,
                },
                track: {
                    height: 20,
                    borderRadius: 6,
                    backgroundColor: "var(--text-color-placeholder)",
                    opacity: 1,
                },
                thumb: {
                    borderRadius: 3,
                    width: 14,
                    height: 14,
                    boxShadow: "none",
                },
                switchBase: {
                    top: 5,
                    opacity: 1,
                    left: 6,
                    "&.Mui-checked": {
                        color: "white",
                    },
                    "&.MuiSwitch-switchBase": {
                        "&.Mui-checked + .MuiSwitch-track": {
                            opacity: 1,
                        },
                    },
                },
                colorPrimary: {
                    color: "white",
                    "&.MuiSwitch-switchBase": {
                        "&.Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "var(--theme-color-dark)",
                            opacity: 1,
                        },
                    },
                },
                sizeSmall: {
                    height: 26,
                    paddingTop: 6,
                    paddingLeft: 11,
                    paddingRight: 11,
                    fontSize: 14,
                    width: 54,
                    "& .MuiSwitch-track": {
                        height: 16,
                    },
                    "& .MuiSwitch-thumb": {
                        width: 10,
                        height: 10,
                    },
                    "& .MuiSwitch-switchBase": {
                        left: 10,
                    },
                    "&. MuiTypography-root": {
                        fontSize: "14px"
                    }
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    botton: "0px",
                },
                paper: {
                    botton: "0px",
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    "& .MuiPaper-root": {
                        borderRadius: 8,
                    },
                },
                dividers: {
                    borderTop: "1px solid var(--outline-color)",
                    borderBottom: "1px solid var(--outline-color)",
                    padding: 24,
                    fontSize: 14,
                    backgroundColor: "var(--background-color-light)",
                    "& .MuiFilledInput-root": {
                        "&.Mui-disabled": {
                            border: "1px solid var(--outline-color)",
                        },
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "16px 24px",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    '& [class^="ri-"]': {
                        color: "var(--text-color-light)",
                    },
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    "&.MuiSnackbar-anchorOriginBottomLeft": {
                        left: "80px",
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "var(--text-color-light)",
                    borderRadius: 8,
                    '& [class^="ri-"]': {
                        marginTop: 0,
                        marginBottom: 0,
                        lineHeight: 1.01,
                    },
                    "&.Mui-disabled": {
                        color: "var(--icon-color-light)",
                    },
                    "&:hover": {
                        backgroundColor: "var(--hover-color)",
                    },
                    "&.MuiIconButton-colorPrimary": {
                        backgroundColor: "var(--theme-color-dark)",
                        color: "var(--white)",
                        "&.Mui-disabled": {
                            backgroundColor: "var(--outline-color)",
                        },
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {},
                paper: {
                    border: "1px solid var(--outline-color);",
                    boxShadow: "0px 1px 2px rgba(32, 37, 41, 0.05), 0px 4px 4px rgba(32, 37, 41, 0.05)",
                    borderRadius: 4,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    height: 40,
                    "&:hover": {
                        backgroundColor: "var(--background-color-light)",
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderColor: "var(--outline-color)",
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    boxShadow: "none",
                    top: 72,
                    maxHeight: "calc(100% - var(--header-height))",
                },
                paperAnchorRight: {
                    borderLeft: "1px solid var(--outline-color)",
                    width: "400px",
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    border: "1px solid var(--outline-color)",
                    textTransform: "initial",
                    "&.separated": {
                        borderLeft: "1px solid var(--outline-color) !important",
                        borderRadius: "4px !important",
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    "&.inline": {
                        "& .MuiBadge-badge": {
                            position: "relative",
                            height: "16px",
                            borderRadius: "4px",
                            transform: "none",
                            backgroundColor: "var(--theme-color-dark)",
                            fontSize: "12px",
                            fontWeight: "700",
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: "var(--text-color-default)",
                    fontWeight: "bold",
                    textAlign: "left",
                },
                body: {
                    fontSize: 14,
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-of-type(odd)": {
                        backgroundColor: "var(--hover-color)",
                    },
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    // boxShadow: "none",
                    // border: "var(--borders)"
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: "var(--white)"
                },
            },
        }
    },
};

export default MuiThemeMod;
